import React, { useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import pluralize from 'pluralize';

import { styled } from 'styles';
import CalendarContext from '../CalendarContext';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import useLayout from 'hooks/useLayout';
import { CalendarItem, CalendarItemType } from '../calendar';
import {
  CalendarTooltip,
  FloatingItemsContainer,
  EventDuration,
  TimeLine,
  CalendarEventsList
} from '../components';
import { getWeekdays, getHours, getEventsWithOverlaps } from '../calendarHelpers';

type EventsPopupData = {
  target?: EventTarget | HTMLElement;
  type: CalendarItemType;
  items: CalendarItem[];
  date?: dayjs.Dayjs;
};

const WeekView: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const layout = useLayout();
  const {
    calendarEvents,
    startDate,
    endDate,
    displayDate,
    goToPrevDate,
    goToNextDate
  } = useContext(CalendarContext);
  const [time, setTime] = useState(dayjs());
  const showTimeLine = endDate?.isSame(dayjs().endOf('week'));
  const [eventsPopupData, setEventsPopupData] = useState<EventsPopupData>();
  const openEventsListModal = Boolean(eventsPopupData);

  const deadlines: CalendarItem[] = [];
  const tasks: CalendarItem[] = [];
  const events: CalendarItem[] = [];
  calendarEvents.forEach(event => {
    switch (event.dateType) {
      case 'deadline':
        deadlines.push(event);
        break;
      case 'task':
        tasks.push(event);
        break;
      case 'event':
        events.push(event);
        break;
    }
  });

  const weekDays = getWeekdays(startDate);
  const hours = getHours();
  const eventCellHeight = layout === 'mobile' ? 40 : 44;
  const pixelsPerMinute = eventCellHeight / 60;

  let eventsListModalTitle;
  switch (eventsPopupData?.type) {
    case 'task':
      eventsListModalTitle = `All tasks - ${eventsPopupData.date!.format('ddd, MMM DD')}`;
      break;
    case 'deadline':
      eventsListModalTitle = `All deadlines - ${eventsPopupData.date!.format('ddd, MMM DD')}`;
      break;
    case 'event':
      eventsListModalTitle = 'Event';
  }

  useEffect(() => {
    const interval = setInterval(() => setTime(dayjs()), 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const eventsList = (
    <CalendarEventsList
      items={eventsPopupData?.items}
      onEmpty={() => setEventsPopupData(undefined)}
    />
  );

  const handleClickEvent = event => {
    if (openEventsListModal) return;
    setEventsPopupData({ type: 'event', items: [event] });
  };

  switch (layout) {
    case 'mobile':
      return (
        <StyledWeekViewMobile eventCellHeight={eventCellHeight} className="week-view mobile">
          <div className="calendar-date-controls">
            <div className="current-date" data-cy="current_date">
              {displayDate}
            </div>
            <Button
              onClick={() => goToPrevDate()}
              className="button-back"
              simple
              data-cy="button_go_to_previous_date">
              <Icon name="arrow-simple" />
            </Button>
            <Button
              onClick={() => goToNextDate()}
              className="button-forward"
              simple
              data-cy="button_go_to_next_date">
              <Icon name="arrow-simple" style={{ transform: 'rotate(180deg)' }} />
            </Button>
          </div>
          <StyledTableWrapper className="table-wrapper scrollable">
            <table>
              <thead>
                <tr className="weekdays">
                  <th className="empty" />
                  {weekDays.map(weekday => (
                    <th
                      key={weekday.unix()}
                      className={clsx({ today: weekday.isSame(time, 'day') })}>
                      <div>{weekday.format('ddd')}</div>
                      <div>{weekday.format('D')}</div>
                    </th>
                  ))}
                </tr>
                <tr className="tasks-and-deadlines">
                  <th className="empty" />
                  {weekDays.map(weekday => {
                    const weekdayDeadlines = deadlines.filter(event =>
                      weekday.isSame(event.start, 'day')
                    );
                    const weekdayTasks = tasks.filter(event => weekday.isSame(event.start, 'day'));
                    const key = weekday.unix();

                    return (
                      <th key={key} className={clsx({ past: weekday.isBefore(dayjs(), 'day') })}>
                        {weekdayDeadlines.length > 0 && (
                          <button
                            onClick={e =>
                              setEventsPopupData({
                                target: e.target,
                                type: 'deadline',
                                date: weekday,
                                items: weekdayDeadlines
                              })
                            }
                            className="deadlines"
                            data-cy="button_set_deadlines_list">
                            {weekdayDeadlines.length}{' '}
                            {pluralize('deadline', weekdayDeadlines.length)}
                          </button>
                        )}

                        {weekdayTasks.length > 0 && (
                          <button
                            onClick={e =>
                              setEventsPopupData({
                                target: e.target,
                                type: 'task',
                                date: weekday,
                                items: weekdayTasks
                              })
                            }
                            className="tasks"
                            data-cy="button_set_tasks_list">
                            {weekdayTasks.length} {pluralize('task', weekdayTasks.length)}
                          </button>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {hours.map(hour => (
                  <tr key={hour}>
                    <td className="hour">
                      {dayjs()
                        .hour(hour)
                        .minute(0)
                        .format('h A')}
                    </td>
                    {weekDays.map(weekday => {
                      const time = weekday.clone().hour(hour);
                      return <td key={time.unix()} className="day-hour-cell" />;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            <FloatingItemsContainer>
              {showTimeLine && <TimeLine time={time} pixelsPerMinute={pixelsPerMinute} />}
              {getEventsWithOverlaps(events).map(({ overlaps, event }) => (
                <EventDuration
                  key={event.id}
                  overlaps={overlaps}
                  pixelsPerMinute={pixelsPerMinute}
                  data={event}
                  onClick={() => handleClickEvent(event)}
                  data-cy="button_set_custom_events_list"
                />
              ))}
            </FloatingItemsContainer>
          </StyledTableWrapper>
          {eventsPopupData?.items?.length && (
            <Modal
              modalTitle={eventsListModalTitle}
              open={openEventsListModal}
              onClose={() => setEventsPopupData(undefined)}
              renderContainer={StyledEventsListModalContainer}>
              {eventsList}
            </Modal>
          )}
        </StyledWeekViewMobile>
      );

    case 'tablet':
      return (
        <StyledWeekViewTablet eventCellHeight={eventCellHeight} className="week-view mobile">
          <StyledTableWrapper className="table-wrapper scrollable">
            <table>
              <thead>
                <tr className="weekdays">
                  <th className="empty" />
                  {weekDays.map(weekday => (
                    <th
                      key={weekday.unix()}
                      className={clsx({ today: weekday.isSame(time, 'day') })}
                      data-cy={`weekday_${weekday.unix()}`}>
                      {weekday.format('ddd')} {weekday.format('D')}
                    </th>
                  ))}
                </tr>
                <tr className="tasks-and-deadlines">
                  <th className="empty" />
                  {weekDays.map(weekday => {
                    const weekdayDeadlines = deadlines.filter(event =>
                      weekday.isSame(event.start, 'day')
                    );
                    const weekdayTasks = tasks.filter(event => weekday.isSame(event.start, 'day'));
                    const key = weekday.unix();

                    return (
                      <th key={key} className={clsx({ past: weekday.isBefore(dayjs(), 'day') })}>
                        {weekdayDeadlines.length > 0 && (
                          <button
                            onClick={e =>
                              setEventsPopupData({
                                target: e.target,
                                type: 'deadline',
                                date: weekday,
                                items: weekdayDeadlines
                              })
                            }
                            className="deadlines"
                            data-cy={`button_set_deadlines_list_${weekday.unix()}`}>
                            {weekdayDeadlines.length}{' '}
                            {pluralize('deadline', weekdayDeadlines.length)}
                          </button>
                        )}

                        {weekdayTasks.length > 0 && (
                          <button
                            onClick={e =>
                              setEventsPopupData({
                                target: e.target,
                                type: 'task',
                                date: weekday,
                                items: weekdayTasks
                              })
                            }
                            className="tasks"
                            data-cy={`button_set_tasks_list_${weekday.unix()}`}>
                            {weekdayTasks.length} {pluralize('task', weekdayTasks.length)}
                          </button>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {hours.map(hour => (
                  <tr key={hour}>
                    <td className="hour">
                      {dayjs()
                        .hour(hour)
                        .minute(0)
                        .format('h A')}
                    </td>
                    {weekDays.map(weekday => {
                      const time = weekday.clone().hour(hour);
                      return (
                        <td
                          key={time.unix()}
                          className="day-hour-cell"
                          data-cy={`day_hour_cell${time.unix()}`}
                        />
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            <FloatingItemsContainer>
              {showTimeLine && <TimeLine time={time} pixelsPerMinute={pixelsPerMinute} />}
              {getEventsWithOverlaps(events).map(({ overlaps, event }) => (
                <EventDuration
                  key={event.id}
                  id={`event-${event.id}`}
                  overlaps={overlaps}
                  pixelsPerMinute={pixelsPerMinute}
                  data={event}
                  onClick={() =>
                    setEventsPopupData({
                      target: document.getElementById(`event-${event.id}`)!,
                      type: 'event',
                      items: [event]
                    })
                  }
                  data-cy="button_set_custom_events_list"
                />
              ))}
            </FloatingItemsContainer>
          </StyledTableWrapper>
          <CalendarTooltip
            target={eventsPopupData?.target}
            open={openEventsListModal}
            tooltipTitle={eventsListModalTitle}
            onClose={() => setEventsPopupData(undefined)}>
            {eventsList}
          </CalendarTooltip>
        </StyledWeekViewTablet>
      );
    case 'desktop':
      return (
        <StyledWeekViewDesktop
          eventCellHeight={eventCellHeight}
          className="week-view desktop scrollable">
          <StyledTableWrapper className="table-wrapper weekdays">
            <table>
              <thead>
                <tr className="weekdays">
                  <th className="time">Time</th>
                  {weekDays.map(weekday => {
                    const today = weekday.isSame(time, 'day');
                    return (
                      <th key={weekday.unix()} className={clsx({ today })}>
                        {weekday.format('ddd')} {weekday.format('D')} {today && '- today'}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            </table>
          </StyledTableWrapper>

          <StyledTableWrapper className="table-wrapper hours">
            <table>
              <thead>
                <tr className="tasks-and-deadlines">
                  <th className="empty" />
                  {weekDays.map(weekday => {
                    const weekdayDeadlines = deadlines.filter(event =>
                      weekday.isSame(event.start, 'day')
                    );
                    const weekdayTasks = tasks.filter(event => weekday.isSame(event.start, 'day'));
                    const key = weekday.unix();

                    return (
                      <th key={key} className={clsx({ past: weekday.isBefore(dayjs(), 'day') })}>
                        {weekdayDeadlines.length > 0 && (
                          <button
                            onClick={e =>
                              setEventsPopupData({
                                target: e.target,
                                type: 'deadline',
                                date: weekday,
                                items: weekdayDeadlines
                              })
                            }
                            className="deadlines"
                            data-cy="button_set_deadlines_list">
                            {weekdayDeadlines.length}{' '}
                            {pluralize('deadline', weekdayDeadlines.length)}
                          </button>
                        )}

                        {weekdayTasks.length > 0 && (
                          <button
                            onClick={e =>
                              setEventsPopupData({
                                target: e.target,
                                type: 'task',
                                date: weekday,
                                items: weekdayTasks
                              })
                            }
                            className="tasks"
                            data-cy="button_set_tasks_list">
                            {weekdayTasks.length} {pluralize('task', weekdayTasks.length)}
                          </button>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {hours.map(hour => (
                  <tr key={hour}>
                    <td className="hour">
                      {dayjs()
                        .hour(hour)
                        .minute(0)
                        .format('h:mm A')}
                    </td>
                    {weekDays.map(weekday => {
                      const time = weekday.clone().hour(hour);
                      return <td key={time.unix()} className="day-hour-cell" />;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            <FloatingItemsContainer>
              {showTimeLine && <TimeLine time={time} pixelsPerMinute={pixelsPerMinute} />}
              {getEventsWithOverlaps(events).map(({ overlaps, event }) => (
                <EventDuration
                  key={event.id}
                  id={`event-${event.id}`}
                  overlaps={overlaps}
                  pixelsPerMinute={pixelsPerMinute}
                  data={event}
                  onClick={() =>
                    setEventsPopupData({
                      target: document.getElementById(`event-${event.id}`)!,
                      type: 'event',
                      items: [event]
                    })
                  }
                  data-cy="button_set_custom_events_list"
                />
              ))}
            </FloatingItemsContainer>
          </StyledTableWrapper>
          <CalendarTooltip
            target={eventsPopupData?.target}
            open={openEventsListModal}
            tooltipTitle={eventsListModalTitle}
            onClose={() => setEventsPopupData(undefined)}>
            {eventsList}
          </CalendarTooltip>
        </StyledWeekViewDesktop>
      );
  }
};

export default WeekView;

const StyledWeekViewMobile = styled.div<{ eventCellHeight: number }>`
  height: 100%;

  table {
    thead {
      font-size: 12px;
      line-height: 16px;

      tr.weekdays {
        th {
          height: 42px;
          vertical-align: middle;
          position: sticky;
          top: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          z-index: 11;
        }
      }

      tr.tasks-and-deadlines {
        height: 59px;

        th {
          vertical-align: top;
          height: 59px;
          padding: 6px 2px;

          &.past {
            .tasks,
            .deadlines {
              border-color: ${props => props.theme.colors.grayDark};
            }
          }

          .tasks,
          .deadlines {
            position: relative;
            height: 20px;
            background: none;
            border: 1px solid;
            border-radius: ${props => props.theme.misc.borderRadius};
            width: 100%;
            font-size: 10px;
            line-height: 10px;
            z-index: 1;
            padding: 0 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            outline: none;
            cursor: pointer;
            transition: 0.3s;
          }

          .deadlines {
            color: ${props => props.theme.colors.red};
            border-color: ${props => props.theme.colors.red};
            margin-bottom: 7px;

            &:hover,
            &:active {
              background: #fce8e9;
            }
          }

          .tasks {
            color: ${props => props.theme.colors.lightGreen};
            border-color: ${props => props.theme.colors.lightGreen};

            &:hover,
            &:active {
              background: #e9ffd7;
            }
          }
        }
      }
    }

    tbody {
      font-size: 10px;
      line-height: 12px;

      tr {
        height: ${props => props.eventCellHeight}px;

        td.hour {
          padding: 4px 1px;
          white-space: nowrap;
        }

        td.day-hour-cell {
        }
      }
    }

    th,
    td {
      border: 1px solid #f1f1f1;
      background: #ffffff;
      background-clip: padding-box;

      &:nth-of-type(1) {
        width: 34px;
      }
    }
  }

  .floating-items-container {
    padding: 102px 0 0 35px;
  }
`;

const StyledWeekViewTablet = styled(StyledWeekViewMobile)`
  table {
    thead {
      tr.weekdays th {
        height: 32px;
      }

      tr.tasks-and-deadlines th {
        height: 76px;
        padding: 12px 11px;

        .tasks,
        .deadlines {
          height: 24px;
        }

        .deadlines {
          margin-bottom: 4px;
        }
      }
    }

    tbody tr td.hour {
      padding: 8px 5px;
      white-space: nowrap;
    }

    td,
    th {
      &:nth-of-type(1) {
        width: 37px;
      }
    }
  }

  .floating-items-container {
    padding: 109px 0 0 38px;
  }
`;

const StyledWeekViewDesktop = styled(StyledWeekViewTablet)`
  position: relative;
  padding-bottom: 1px;

  .table-wrapper {
    overflow: hidden;

    &.weekdays {
      flex-shrink: 0;
      margin-bottom: 10px;
      height: auto;
      position: sticky;
      z-index: 10;
      top: 0;

      th {
        text-align: left;
        vertical-align: middle;
        height: 32px;
        padding: 0 8px;

        &.time {
          font-size: 10px;
          line-height: 12px;
          color: ${props => props.theme.colors.gray};
          text-align: left;
        }

        &.today {
          border: none;
          color: #000;
          border-radius: 0;
        }
      }
    }

    &.hours {
      height: auto;
    }
  }

  table {
    th,
    tr {
      &:first-of-type {
        width: 59px;
      }
    }

    thead tr.tasks-and-deadlines {
      th {
        text-align: left;
        height: 76px;
        padding: 10px 8px;
        vertical-align: top;

        &.past button {
          color: ${props => props.theme.colors.grayDark} !important;
        }

        .tasks,
        .deadlines {
          width: 100%;
          border: none;
          padding: 0 6px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          border-radius: 1px 3px 3px 1px;
          text-align: left;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background: black;
            height: 100%;
            width: 2px;
            border-radius: 4px;
          }
        }

        .deadlines {
          color: ${props => props.theme.colors.red};
          margin-bottom: 8px;

          &::before {
            background: ${props => props.theme.colors.red};
          }
        }

        .tasks {
          color: ${props => props.theme.colors.lightGreen};

          &::before {
            background: ${props => props.theme.colors.lightGreen};
          }
        }
      }
    }
  }

  .floating-items-container {
    padding: 76px 0 0 59px;
  }
`;

const StyledEventsListModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .modal-bg {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;

    .xp-modal {
      border-radius: 0;
      margin: 0;
      width: 100%;
    }
  }
`;

const StyledTableWrapper = styled.div`
  position: relative;
  border: 1px solid #f1f1f1;
  border-radius: ${props => props.theme.misc.borderRadius};
  box-sizing: border-box;

  td,
  th {
    border: 1px solid #f1f1f1;
    &.today {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: ${props => props.theme.colors.black};
      border: 1px solid transparent;
      @media (max-width: ${props => props.theme.breakpoints.md}) {
        border-color: ${props => props.theme.colors.black};
        border-radius: ${props => props.theme.misc.borderRadius};
      }
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;
    color: ${props => props.theme.colors.grayDark};
  }
`;
