import React from 'react';
import clsx from 'clsx';
import { styled } from 'styles';

export interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {}

const defaultLoader = (
  <svg width="60" height="66" viewBox="0 0 60 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.9168 0L5.8335 17.4797V47.1105H9.54732V19.2023L32.9168 4.11991L56.2858 19.2023V57.44H20.2569V60.7895H60.0002V17.4806L32.9168 0Z"
      fill="#D72000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.84375 65.9998H9.56049V52.1167H5.84375V65.9998Z"
      fill="#1F1F1F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 60.7333H15.4053V57.3838H0V60.7333Z"
      fill="#1F1F1F"
    />
  </svg>
);

const Loader: React.FC<LoaderProps> = ({ className, children, ...props }) => {
  return (
    <StyledLoader className={clsx('loader', className)} {...props}>
      {children || defaultLoader}
    </StyledLoader>
  );
};

export default Loader;

const StyledLoader = styled.span`
  display: inline-block;
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }
`;
