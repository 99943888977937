import React, { useState, useEffect, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { capitalize } from 'lodash';
import { useParams, navigate } from '@reach/router';
import notify from 'notify';
import { getUsers } from 'api/users';
import useLayout from 'hooks/useLayout';
import { DropdownOption } from 'components/Dropdown';
import {
  getCalendarData,
  createDeadline,
  createTask,
  createEvent,
  editDeadline,
  editTask,
  editEvent,
  deleteDeadline,
  deleteTask,
  deleteEvent,
  showHideTask,
  completeTask,
  showHideDeadline,
  showHideEvent
} from './api';
import { mapUsers } from './mapping';
import { baseRoute } from './calendarHelpers';
import { CalendarItem, CalendarProjectType } from './calendar';

dayjs.extend(isSameOrAfter);

type View = 'month' | 'week' | 'day' | 'schedule';

interface CalendarContextValues {
  calendarData: CalendarItem[];
  calendarEvents: CalendarItem[];
  calendarHiddenEvents: CalendarItem[];
  displayDate?: string;
  documentsOptions: DropdownOption[];
  endDate?: dayjs.Dayjs;
  filterAssignTo: number[];
  filterProjectType: CalendarProjectType[];
  loading: boolean;
  openDeleteEvent: number | null;
  openEvent: Partial<CalendarItem> | null;
  projectsOptions: DropdownOption[];
  projectTypeOptions: DropdownOption[];
  selectedDate: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
  usersOptions: DropdownOption[];
  view: View;
  addEvent: (value: {}) => void;
  deleteCalendarItem: (value: CalendarItem) => void;
  editCalendarItem: (value: {}) => void;
  fetchCalendarData: () => void;
  goToNextDate: (unit?: dayjs.OpUnitType) => void;
  goToPrevDate: (unit?: dayjs.OpUnitType) => void;
  goToToday: () => void;
  setCalendarData: React.Dispatch<React.SetStateAction<CalendarItem[]>>;
  setFilterAssignTo: React.Dispatch<React.SetStateAction<number[]>>;
  setFilterProjectType: React.Dispatch<React.SetStateAction<CalendarProjectType[]>>;
  setOpenDeleteEvent: React.Dispatch<React.SetStateAction<number | null>>;
  setOpenEvent: React.Dispatch<React.SetStateAction<Partial<CalendarItem> | null>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  setView: (view: View) => void;
  toggleEventVisibility: (value: CalendarItem) => void;
  toggleTaskCompleteStatus: (value: CalendarItem) => void;
}

const CalendarContext = React.createContext<CalendarContextValues>({} as CalendarContextValues);

const getDisplayDate = (
  view: View,
  startDate: dayjs.Dayjs,
  endDate?: dayjs.Dayjs,
  selectedDate?: dayjs.Dayjs,
  isMobile?: boolean
) => {
  switch (view) {
    case 'day':
      return selectedDate?.format('ddd, MMMM D, YYYY') || '';
    case 'week':
      return `${startDate.format('MMM D')} - ${
        endDate?.isAfter(startDate, 'month')
          ? endDate?.format('MMM D, YYYY')
          : endDate?.format('D, YYYY')
      }`;
    case 'month':
      return startDate.format('MMM YYYY');
    case 'schedule':
      return isMobile
        ? selectedDate!.format('ddd, MMMM D, YYYY')
        : selectedDate!.format('MMM YYYY');
    default:
      break;
  }
};

const projectTypeOptions = [
  { value: 'sale', text: 'Sell' },
  { value: 'purchase', text: 'Buy' }
];

export const ContextWrapper = ({ children }) => {
  const layout = useLayout();
  const { view: viewParam } = useParams();

  if (!viewParam) window.location.href = `${window.location.href}schedule`;

  let view: View = 'schedule';
  if (
    viewParam === 'month' ||
    viewParam === 'week' ||
    viewParam === 'day' ||
    viewParam === 'schedule'
  ) {
    view = viewParam;
  }
  const [usersOptions, setUsersOptions] = useState<DropdownOption[]>([]);
  const [projectsOptions, setProjectsOptions] = useState<DropdownOption[]>([]);
  const [documentsOptions, setDocumentsOptions] = useState<DropdownOption[]>([]);
  const [filterProjectType, setFilterProjectType] = useState<CalendarProjectType[]>([
    'sale',
    'purchase'
  ]);
  const [filterAssignTo, setFilterAssignTo] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs());
  const [calendarData, setCalendarData] = useState<CalendarItem[]>([]);
  const [openEvent, setOpenEvent] = useState<Partial<CalendarItem> | null>(null);
  const [openDeleteEvent, setOpenDeleteEvent] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [startDateSetupComplete, setStartDateSetupComplete] = useState(false);
  const dateUnit = view === 'schedule' ? 'day' : view;

  const calendarEvents = useMemo(() => {
    let result = calendarData.filter(item => item.visible !== false);
    if (filterAssignTo.length !== usersOptions.length) {
      result = result.filter(
        event => event.dateType === 'deadline' || filterAssignTo.includes(event.assigneeId)
      );
    }
    if (filterProjectType.length !== projectTypeOptions.length) {
      result = result.filter(event => filterProjectType.includes(event.projectType));
    }
    return result;
  }, [calendarData, filterAssignTo, filterProjectType, usersOptions.length]);

  const calendarHiddenEvents = useMemo(() => {
    const events = calendarData.filter(item => !item.visible && item.dateType !== 'event');
    switch (view) {
      case 'day':
        return events.filter(event => event.start.isSame(selectedDate, 'day'));
      case 'month':
        if (layout === 'mobile') {
          return events.filter(event => event.start.isSame(selectedDate, 'day'));
        }
        return events;
      case 'schedule':
        return events.filter(event => event.start.isSameOrAfter(selectedDate, 'day'));

      default:
        return events;
    }
  }, [calendarData, view, layout, selectedDate]);

  const endDate = useMemo(() => {
    switch (view) {
      case 'week':
        return dayjs(startDate).endOf('week');
      case 'month':
      case 'day':
      case 'schedule':
        return dayjs(startDate).endOf('month');
      default:
        return dayjs(startDate).endOf(dateUnit);
    }
  }, [dateUnit, startDate, view]);

  const displayDate = getDisplayDate(view, startDate, endDate, selectedDate, layout === 'mobile');

  const goToNextDate = (unit?: dayjs.OpUnitType) => {
    setLoading(true);
    // eslint-disable-next-line no-param-reassign
    if (!unit) unit = dateUnit;
    const newDate = dayjs(startDate)
      .add(1, unit)
      .startOf(unit);
    setStartDate(newDate);
    setSelectedDate(newDate.clone().startOf(unit));
  };

  const goToPrevDate = (unit?: dayjs.OpUnitType) => {
    setLoading(true);
    // eslint-disable-next-line no-param-reassign
    if (!unit) unit = dateUnit;
    const newDate = dayjs(startDate)
      .subtract(1, unit)
      .startOf(unit);
    setStartDate(newDate);
    setSelectedDate(newDate.clone());
  };

  const goToToday = () => {
    setLoading(true);
    setStartDate(dayjs().startOf(dateUnit));
    setSelectedDate(dayjs());
  };

  const fetchCalendarData = useCallback(async () => {
    let start: number;
    let end: number;
    switch (view) {
      case 'month':
      case 'day':
      case 'schedule':
        start = dayjs(startDate)
          .startOf('month')
          .startOf('week')
          .unix();
        end = dayjs(startDate)
          .add(1, 'month')
          .endOf('month')
          .endOf('week')
          .unix();
        break;
      default:
        start = dayjs(startDate).unix();
        end = dayjs(endDate).unix();
    }

    try {
      const { calendar, documents, listings } = await getCalendarData({ start, end });
      setCalendarData(calendar);
      setDocumentsOptions(documents);
      setProjectsOptions(listings);
    } catch (err) {
      notify(err.message);
    }
    setLoading(false);
  }, [endDate, startDate, view]);

  const addEvent = async values => {
    try {
      let createRequest;
      if (values.dateType === 'deadline') createRequest = createDeadline;
      else if (values.dateType === 'task') createRequest = createTask;
      else createRequest = createEvent;
      await createRequest(values);
      fetchCalendarData();
      setOpenEvent(null);
      notify(`${capitalize(values.dateType)} successfully created`);
    } catch (err) {
      notify(err.message);
    }
  };

  const editCalendarItem = async values => {
    try {
      let updateRequest;
      if (values.dateType === 'deadline') updateRequest = editDeadline;
      else if (values.dateType === 'task') updateRequest = editTask;
      else updateRequest = editEvent;

      await updateRequest(values);
      fetchCalendarData();
      setOpenEvent(null);
      notify(`${capitalize(values.dateType)} successfully edited`);
    } catch (err) {
      notify(err.message);
    }
  };

  const deleteCalendarItem = async (value: CalendarItem) => {
    try {
      const item = calendarData.find(
        item => item.id === value.id && item.dateType === value.dateType
      );
      const deleteRequest =
        // eslint-disable-next-line no-nested-ternary
        item?.dateType === 'deadline'
          ? deleteDeadline
          : item?.dateType === 'task'
          ? deleteTask
          : deleteEvent;
      if (item) {
        await deleteRequest(item);

        fetchCalendarData();
        setOpenEvent(null);
      }
      notify(`${capitalize(item?.dateType)} successfully deleted`);
    } catch (err) {
      notify(err.message);
    }
  };

  const setView = (newView: View) => {
    if (view !== newView) {
      setLoading(true);
      navigate(`${baseRoute}/${newView}`);
    }
  };

  const toggleEventVisibility = async (data: CalendarItem) => {
    try {
      if (data.dateType === 'deadline') await showHideDeadline(data);
      else if (data.dateType === 'task') await showHideTask(data);
      else await showHideEvent(data);
      fetchCalendarData();
      const notificationText = data.visible ? 'Item hidden' : 'Item shown';
      notify(notificationText);
    } catch (err) {
      notify(err.message);
    }
  };

  const toggleTaskCompleteStatus = async (data: CalendarItem) => {
    try {
      await completeTask(data);
      fetchCalendarData();
      notify('Task was successfully updated');
    } catch (err) {
      notify(err.message);
    }
  };

  useEffect(() => {
    if (startDateSetupComplete) {
      fetchCalendarData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, startDateSetupComplete]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await getUsers();
        const mappedData = mapUsers(users);
        const allIds = mappedData.map(item => item.value);
        setUsersOptions(mappedData);
        setFilterAssignTo(allIds);
      } catch (err) {
        notify(err.message);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    switch (view) {
      case 'schedule':
      case 'day':
        setStartDate(dayjs().startOf('day'));
        break;
      case 'week':
        setStartDate(dayjs().startOf('week'));
        break;
      case 'month':
        setStartDate(dayjs().startOf('month'));
        break;
      default:
        break;
    }
    setStartDateSetupComplete(true);
  }, [view]);

  const wsCloseElements = document.querySelectorAll('.ws_fade, .ws_close');

  useEffect(() => {
    const handleWsClose = e => {
      e.stopPropagation();
      fetchCalendarData();
    };

    if (wsCloseElements.length > 0) {
      wsCloseElements.forEach(item => {
        if (item !== null) item.addEventListener('mousedown', handleWsClose, false);
      });
    }

    return () => {
      wsCloseElements.forEach(item => {
        if (item !== null) item.removeEventListener('mousedown', handleWsClose, false);
      });
    };
  }, [fetchCalendarData, wsCloseElements]);

  const contextValues: CalendarContextValues = {
    calendarData,
    calendarEvents,
    calendarHiddenEvents,
    displayDate,
    documentsOptions,
    endDate,
    filterAssignTo,
    filterProjectType,
    loading,
    openDeleteEvent,
    openEvent,
    projectsOptions,
    projectTypeOptions,
    selectedDate,
    startDate,
    usersOptions,
    view,
    addEvent,
    deleteCalendarItem,
    editCalendarItem,
    fetchCalendarData,
    goToNextDate,
    goToPrevDate,
    goToToday,
    setCalendarData,
    setFilterAssignTo,
    setFilterProjectType,
    setOpenDeleteEvent,
    setOpenEvent,
    setSelectedDate,
    setStartDate,
    setView,
    toggleEventVisibility,
    toggleTaskCompleteStatus
  };

  return <CalendarContext.Provider value={contextValues}>{children}</CalendarContext.Provider>;
};

export default CalendarContext;
