import React, { useState, useContext, useMemo, useEffect } from 'react';
import pluralize from 'pluralize';
import clsx from 'clsx';
import { capitalize } from 'lodash';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import MainLayout from 'components/MainLayout';
import { ConfirmDeletionModal, CalendarLoader, HiddenEventsButton } from './components';
import EditEventModal from './components/EditEventModal';
import CalendarContext, { ContextWrapper } from './CalendarContext';
import DayView from './DayView';
import WeekView from './WeekView';
import MonthView from './MonthView';
import ScheduleView from './ScheduleView';
import { CalendarItem } from './calendar';

const viewOptions = [
  { value: 'month', text: 'Month' },
  { value: 'week', text: 'Week' },
  { value: 'day', text: 'Day' },
  { value: 'schedule', text: 'Schedule' }
];

const dateTypeOptions = [
  { value: 'event', text: 'Event' },
  { value: 'deadline', text: 'Deadline' },
  { value: 'task', text: 'Task' }
];

const CalendarPage = () => {
  const {
    calendarHiddenEvents,
    displayDate,
    filterAssignTo,
    filterProjectType,
    loading,
    openEvent,
    projectTypeOptions,
    usersOptions,
    view,
    deleteCalendarItem,
    goToNextDate,
    goToPrevDate,
    goToToday,
    setFilterAssignTo,
    setFilterProjectType,
    setOpenEvent,
    setView
  } = useContext(CalendarContext);
  const layout = useLayout();
  const [showCalendarMiniature, setShowCalendarMiniature] = useState(false);
  const showMiniatureDropdown = view !== 'week' && view !== 'month' && layout !== 'desktop';
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [openDeleteEvent, setOpenDeleteEvent] = useState<CalendarItem | null>(null);
  const projectTypeDropdownText = useMemo(() => {
    if (filterProjectType.includes('purchase') && filterProjectType.includes('sale')) return 'All';
    return projectTypeOptions.find(item => item.value === filterProjectType[0])?.text;
  }, [filterProjectType, projectTypeOptions]);
  const filterAssignToText =
    filterAssignTo.length === usersOptions.length
      ? 'All'
      : `${filterAssignTo.length} ${pluralize('user', filterAssignTo.length)}`;

  const handleDeleteEvent = () => {
    deleteCalendarItem(openDeleteEvent);
    setOpenDeleteEvent(null);
  };

  const showMobileDayViewMiniature =
    (view === 'day' || view === 'schedule') && showCalendarMiniature;

  useEffect(() => {
    if (view === 'month') setShowCalendarMiniature(true);
    else if (view === 'week') setShowCalendarMiniature(false);
    else if (view === 'schedule' && layout !== 'mobile') setShowCalendarMiniature(false);
  }, [layout, view]);

  const getCalendarView = () => {
    switch (view) {
      case 'month':
        return <MonthView showCalendarMiniature={showCalendarMiniature} />;
      case 'week':
        return <WeekView />;
      case 'day':
        return <DayView showCalendarMiniature={showCalendarMiniature} />;
      default:
        return <ScheduleView showCalendarMiniature={showCalendarMiniature} />;
    }
  };

  const viewComponent = loading ? <CalendarLoader /> : getCalendarView();

  const getLayout = layout => {
    switch (layout) {
      case 'desktop':
        return (
          <StyledLayoutDesktop
            className="calendar-page desktop styleguide-v2 scrollable"
            data-cy="calendar_page_desktop">
            <div className="calendar-controls">
              <StyledWhiteButton
                onClick={goToToday}
                className="button-today"
                data-cy="button_go_to_today">
                Today
              </StyledWhiteButton>
              <Button
                onClick={() => goToPrevDate()}
                className="button-back"
                simple
                data-cy="button_go_to_previous_date">
                <Icon name="arrow-simple" />
              </Button>
              <Button
                onClick={() => goToNextDate()}
                className="button-forward"
                simple
                data-cy="button_go_to_next_date">
                <Icon name="arrow-simple" style={{ transform: 'rotate(180deg)' }} />
              </Button>
              <div className="current-date" data-cy="current_date">
                {displayDate}
              </div>
              <HiddenEventsButton events={calendarHiddenEvents} />
              <Dropdown
                options={projectTypeOptions}
                value={filterProjectType}
                onChange={setFilterProjectType}
                label="Project type:"
                text={projectTypeDropdownText}
                multiple
                buttonLike
                data-cy="dropdown_project_type"
              />
              <StyledDropdown
                options={usersOptions}
                value={filterAssignTo}
                onChange={setFilterAssignTo}
                label="Assign to:"
                text={filterAssignToText}
                multiple
                optionAll
                buttonLike
                data-cy="dropdown_assigned_to"
              />
              <Dropdown
                options={viewOptions}
                value={view}
                onChange={setView}
                label="View:"
                buttonLike
                data-cy="dropdown_view"
              />
              <Button
                className="button-add"
                onClick={() => setOpenEvent({})}
                data-cy="button_add_new">
                Add new
              </Button>
            </div>
            <div className="calendar-view" data-cy="calendar_view">
              {viewComponent}
            </div>
          </StyledLayoutDesktop>
        );

      case 'tablet':
        return (
          <StyledLayoutTablet
            className="calendar-page tablet styleguide-v2 scrollable"
            data-cy="calendar_page_tablet">
            <div className="calendar-controls">
              <StyledWhiteButton
                onClick={goToToday}
                className="button-today"
                data-cy="button_go_to_today">
                Today
              </StyledWhiteButton>
              <HiddenEventsButton events={calendarHiddenEvents} />
              <Dropdown
                options={projectTypeOptions}
                value={filterProjectType}
                onChange={setFilterProjectType}
                label="Project type:"
                text={projectTypeDropdownText}
                multiple
                buttonLike
                data-cy="dropdown_project_type"
              />
              <StyledDropdown
                options={usersOptions}
                value={filterAssignTo}
                onChange={setFilterAssignTo}
                label="Assign to:"
                text={filterAssignToText}
                multiple
                optionAll
                buttonLike
                data-cy="dropdown_assigned_to"
              />
              <Dropdown
                value={view}
                onChange={setView}
                label="View:"
                options={viewOptions}
                buttonLike
                data-cy="dropdown_view"
              />
              <Button
                className="button-add"
                onClick={() => setOpenEvent({})}
                data-cy="button_add_new">
                Add new
              </Button>
            </div>
            <div className="calendar-controls">
              {showMiniatureDropdown && view !== 'schedule' ? (
                <button
                  onClick={() => setShowCalendarMiniature(value => !value)}
                  className={clsx('current-date', { expand: showCalendarMiniature })}
                  data-cy="button_show_calendar_miniature">
                  {displayDate}
                  <Icon name="chevron" />
                </button>
              ) : (
                <div className="current-date" data-y="current_date">
                  {displayDate}
                </div>
              )}
              {!showMobileDayViewMiniature && (
                <>
                  <Button
                    onClick={() => goToPrevDate()}
                    className="button-back"
                    simple
                    data-cy="button_go_to_previous_date">
                    <Icon name="arrow-simple" />
                  </Button>
                  <Button
                    onClick={() => goToNextDate()}
                    className="button-forward"
                    simple
                    data-cy="button_go_to_next_date">
                    <Icon name="arrow-simple" style={{ transform: 'rotate(180deg)' }} />
                  </Button>
                </>
              )}
            </div>
            <div className="calendar-view" data-cy="calendar_view">
              {viewComponent}
            </div>
          </StyledLayoutTablet>
        );

      case 'mobile':
        return (
          <StyledLayoutMobile
            className="calendar-page mobile styleguide-v2 scrollable"
            data-cy="calendar_page_mobile">
            <div className="calendar-controls">
              <StyledWhiteButton onClick={goToToday} data-cy="button_go_to_today">
                Today
              </StyledWhiteButton>
              <Dropdown
                value={view}
                onChange={setView}
                label="View:"
                placeholder="Day"
                options={viewOptions}
                buttonLike
                data-cy="dropdown_view"
              />
              <HiddenEventsButton events={calendarHiddenEvents} />
              <Button
                onClick={() => setFiltersModalOpen(true)}
                className="button-filter"
                secondary
                data-cy="button_open_filters">
                <Icon name="filter" />
              </Button>
              <Button
                onClick={() => setOpenEvent({})}
                className="button-add"
                data-cy="button_add_new">
                <Icon name="plus" />
              </Button>
            </div>
            {showMiniatureDropdown && (
              <div className="calendar-date-controls">
                <button
                  onClick={() => setShowCalendarMiniature(value => !value)}
                  className={clsx('current-date', { expand: showCalendarMiniature })}
                  data-cy="button_show_calendar_miniature">
                  {displayDate}
                  <Icon name="chevron" />
                </button>
                {!showCalendarMiniature && (
                  <>
                    <Button
                      onClick={() => goToPrevDate()}
                      className="button-back"
                      simple
                      data-cy="button_go_to_previous_date">
                      <Icon name="arrow-simple" />
                    </Button>
                    <Button
                      onClick={() => goToNextDate()}
                      className="button-forward"
                      simple
                      data-cy="button_go_to_next_date">
                      <Icon name="arrow-simple" style={{ transform: 'rotate(180deg)' }} />
                    </Button>
                  </>
                )}
              </div>
            )}
            <div className="calendar-view" data-cy="calendar_view">
              {viewComponent}
            </div>
            <StyledFormatsModal
              renderContainer={StyledFormatsModalContainer}
              modalTitle="Calendar filter"
              open={filtersModalOpen}
              onClose={() => setFiltersModalOpen(false)}
              data-cy="modal_calendar_filters">
              <Dropdown
                options={projectTypeOptions}
                value={filterProjectType}
                onChange={setFilterProjectType}
                label={
                  <>
                    Project type:{' '}
                    <button
                      onClick={() => setFilterProjectType(['sale', 'purchase'])}
                      className="button-clear-filters"
                      data-cy="button_clear_project_type">
                      CLEAR
                    </button>
                  </>
                }
                text={projectTypeDropdownText}
                multiple
                tags
                data-cy="dropdown_project_type"
              />
              <StyledDropdown
                options={usersOptions}
                value={filterAssignTo}
                onChange={setFilterAssignTo}
                label={
                  <>
                    Assign to:{' '}
                    <button
                      onClick={() => setFilterAssignTo(usersOptions.map(item => item.value))}
                      className="button-clear-filters"
                      data-cy="button_clear_assigned_to">
                      CLEAR
                    </button>
                  </>
                }
                text={filterAssignToText}
                multiple
                optionAll
                tags
                data-cy="dropdown_assigned_to"
              />
            </StyledFormatsModal>
          </StyledLayoutMobile>
        );
      default:
        break;
    }
  };

  return (
    <MainLayout>
      {getLayout(layout)}
      <EditEventModal
        open={Boolean(openEvent)}
        data={openEvent}
        dateType={openEvent?.dateType}
        onClose={() => setOpenEvent(null)}
        onDeleteClick={setOpenDeleteEvent}
      />

      {openEvent?.id && (
        <ConfirmDeletionModal
          modalTitle={`Delete ${openEvent?.dateType}`}
          open={Boolean(openDeleteEvent)}
          onSubmit={handleDeleteEvent}
          onClose={() => setOpenDeleteEvent(null)}
          data-cy="modal_delete">
          <p>{`${capitalize(openEvent?.dateType)} will be removed completely from the system.`}</p>
        </ConfirmDeletionModal>
      )}
    </MainLayout>
  );
};

export default props => (
  <ContextWrapper>
    <CalendarPage {...props} />
  </ContextWrapper>
);

const StyledLayoutMobile = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding-right: 4px;

  h1,
  h2,
  h3,
  h4 {
    margin-top: 0;
  }

  button {
    &.button-back,
    &.button-forward {
      width: 32px;
      height: 32px;
      margin-left: 8px;
      transition: ${props => props.theme.transitions.standart};
      &:hover,
      &:active {
        background: transparent;
      }
    }
  }

  .calendar-controls {
    display: flex;
    margin-bottom: 12px;
    flex-shrink: 0;

    .dropdown {
      margin: 0 auto 0 4px;
    }

    .hidden-events-button,
    .button-filter,
    .button-add {
      margin-left: 4px;
    }

    .button-filter,
    .button-add {
      width: 32px;
      padding: 0;
      flex-shrink: 0;

      .icon {
        vertical-align: middle;
      }
    }
  }

  .calendar-date-controls {
    display: flex;
    margin-bottom: 8px;
    flex-shrink: 0;
  }

  .current-date {
    display: flex;
    align-items: center;
    margin-right: auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    border: none;
    background: none;
    padding: 0;
    outline: none;
    cursor: pointer;

    &.expand .icon {
      transform: rotate(180deg);
      fill: ${props => props.theme.colors.red};
    }
  }

  .calendar-view {
    height: 100%;
    position: relative;
  }
`;

const StyledLayoutTablet = styled(StyledLayoutMobile)`
  .calendar-controls {
    margin-bottom: 16px;

    .button-today {
      margin-right: auto;
    }

    .button-add {
      width: auto;
      padding: 0 24px;
    }

    .dropdown,
    .button-add {
      margin: 0 0 0 12px !important;
    }

    .hidden-events-button {
      margin: 0 0 0 8px !important;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .calendar-miniature {
      .weeks {
        margin-top: 10px;
      }

      .cell {
        height: 40px;
        padding: 5px 8px;
      }
    }
  }
`;

const StyledLayoutDesktop = styled(StyledLayoutTablet)`
  .calendar-controls {
    margin-bottom: 24px;

    .button-today {
      margin-right: 16px;
    }

    .current-date {
      margin: 0 auto 0 8px;
    }
  }

  button {
    &.button-back,
    &.button-forward {
      margin: 0 4px 0 0;
      &:hover,
      &:active {
        background: ${props => props.theme.colors.pink};
      }
    }
  }
`;

const StyledWhiteButton = styled(Button)`
  width: 84px;
  background: #fff;
  border: 1px solid #000;
  color: #000;

  &:hover {
    border-color: #000;
    border-width: 1px;
  }
`;

const StyledFormatsModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .modal-bg {
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 20px;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 0;
    }
  }
`;

const StyledFormatsModal = styled(Modal)`
  width: 100%;
  min-height: 288px;
  margin: 0 0 auto 0;
  border-radius: 0;

  .modal-body {
    display: flex;
    flex-direction: column;

    .dropdown,
    .button {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .button-clear-filters {
    float: right;
    font-size: 10px;
    line-height: 16px;
    background: none;
    border: none;
    text-transform: uppercase;
    color: ${props => props.theme.colors.red};
    padding: 0 2px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  .checkbox label {
    white-space: normal;
  }
`;
