import React, { useMemo, useState, useEffect } from 'react';

import { styled } from 'styles';
import Modal, { ModalProps } from 'components/Modal';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { CalendarItem, CalendarItemType } from '../../calendar';
import EditCustomEventForm from './EditCustomEventForm';
import EditTaskForm from './EditTaskForm';
import EditDeadlineForm from './EditDeadlineForm';

type ProjectTypeOptions = { value: CalendarItemType; text: string }[];

interface EditEventProps extends ModalProps {
  data: Partial<CalendarItem> | null;
  dateType?: CalendarItemType;
  onDeleteClick: (value: CalendarItem | null) => void;
}

const EditEventModal: React.FC<EditEventProps> = ({
  data,
  dateType: dateTypeProp,
  onDeleteClick,
  ...props
}) => {
  const [dateType, setDateType] = useState('task');
  const shouldDisable = Boolean(data?.id) && !data?.editable;

  useEffect(() => {
    if (dateTypeProp) setDateType(dateTypeProp);
  }, [dateTypeProp]);

  const form = useMemo(() => {
    switch (dateType) {
      case 'event':
        return <EditCustomEventForm restrictEdit={shouldDisable} />;
      case 'task':
        return <EditTaskForm />;
      case 'deadline':
        return <EditDeadlineForm />;
      default:
        break;
    }
  }, [dateType, shouldDisable]);

  const projectTypeOptions: ProjectTypeOptions = [
    { value: 'event', text: 'Event' },
    { value: 'deadline', text: 'Deadline' },
    { value: 'task', text: 'Task' }
  ];

  const isEditing = data?.id;

  return (
    <StyledModal
      modalTitle={isEditing ? `Edit ${data?.dateType}` : `Add new`}
      data-cy="modal_edit_add_new"
      {...props}>
      {isEditing && (
        <Button
          simple
          className="delete-btn"
          onClick={() => onDeleteClick(data as CalendarItem)}
          data-cy="button_delete_calendar_event">
          <Icon name="delete" size={12} />
        </Button>
      )}
      {!isEditing && (
        <Dropdown
          options={projectTypeOptions}
          label="Choose type"
          value={dateType}
          onChange={setDateType}
          data-cy="dropdown_date_type"
        />
      )}
      {form}
    </StyledModal>
  );
};

export default EditEventModal;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;

    .delete-btn {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 12px;
      right: 44px;
      fill: ${props => props.theme.colors.grayDark};
    }
  }
`;
