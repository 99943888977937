import React, { useContext, useMemo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { styled } from 'styles';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import InputDate from 'components/InputDate';
import useAuthContext from 'hooks/useAuthContext';
import CalendarContext from '../../CalendarContext';
import { getTimeOptions, getDefaultEndTime } from './helpers';

interface EditCustomEventFormProps {
  restrictEdit?: boolean;
  isClosingEvent?: boolean;
}

const EditCustomEventForm: React.FC<EditCustomEventFormProps> = ({
  restrictEdit,
  isClosingEvent
}) => {
  const {
    openEvent: initialData,
    addEvent,
    editCalendarItem,
    projectsOptions,
    usersOptions
  } = useContext(CalendarContext);
  const { profile } = useAuthContext();
  const start = initialData?.start ? dayjs(initialData.start).format('hh:mm A') : undefined;
  const end = initialData?.end ? dayjs(initialData.end).format('hh:mm A') : undefined;
  const { register, control, errors, setValue, handleSubmit, watch } = useForm({
    defaultValues: { ...initialData, date: initialData?.start?.format('MM/DD/YYYY'), start, end }
  });
  const { startOptions, endOptions } = useMemo(() => getTimeOptions(initialData?.start), [
    initialData
  ]);
  const isEditing = Boolean(initialData?.id);
  const currentUser = usersOptions.find(user => user.search === profile?.email);
  const personalEvent = watch('personalEvent', false);

  const submit = form => {
    if (isEditing) editCalendarItem({ ...form, id: initialData?.id });
    else addEvent(form);
  };

  useEffect(() => {
    if (personalEvent && currentUser) setValue('assigneeId', currentUser?.value);
  }, [personalEvent]);

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <input type="hidden" ref={register} name="dateType" value="event" />
      <Checkbox
        ref={register}
        label="Personal event"
        name="personalEvent"
        disabled={restrictEdit || initialData?.personalEvent}
        data-cy="checkbox_personal_event"
      />

      <Input
        ref={register({ required: 'Required' })}
        name="title"
        error={errors.title?.message}
        label="Title"
        placeholder="Enter"
        disabled={restrictEdit || isClosingEvent}
        data-cy="input_title"
      />
      <Controller
        control={control}
        rules={{
          required: 'Required',
          pattern: {
            value: /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/,
            message: 'Invalid date'
          }
        }}
        name="date"
        defaultValue={dayjs().format('MM/DD/YYYY')}
        render={controllerProps => (
          <InputDate
            inputProps={{
              label: 'Date',
              error: errors.date?.message,
              disabled: restrictEdit,
              'data-cy': 'input_date'
            }}
            {...controllerProps}
          />
        )}
      />
      <div className="time-options">
        <Controller
          control={control}
          rules={{ required: 'Required' }}
          name="start"
          defaultValue=""
          render={({ onChange, ...controllerProps }) => (
            <Dropdown
              options={startOptions}
              label="Start"
              placeholder="Select"
              disabled={restrictEdit}
              error={errors.start?.message}
              onChange={value => {
                onChange(value);
                setValue('end', getDefaultEndTime(value));
              }}
              data-cy="dropdown_start"
              {...controllerProps}
            />
          )}
        />
        <Controller
          control={control}
          rules={{ required: 'Required' }}
          name="end"
          defaultValue=""
          render={controllerProps => (
            <Dropdown
              options={endOptions}
              label="End"
              placeholder="Select"
              disabled={restrictEdit}
              error={errors.end?.message}
              data-cy="dropdown_end"
              {...controllerProps}
            />
          )}
        />
      </div>
      {!personalEvent && !initialData?.personalEvent && (
        <Controller
          control={control}
          rules={{ required: 'Required' }}
          name="propertyId"
          defaultValue=""
          render={controllerProps => (
            <Dropdown
              search
              options={projectsOptions}
              label="Project"
              placeholder="Select"
              disabled={isEditing}
              error={errors.propertyId?.message}
              data-cy="dropdown_project"
              {...controllerProps}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name="assigneeId"
        defaultValue=""
        render={controllerProps => (
          <Dropdown
            search
            options={usersOptions}
            label={
              <>
                Assigned to <span className="optional">(optional)</span>
              </>
            }
            placeholder="Select"
            disabled={restrictEdit || personalEvent || initialData?.personalEvent}
            error={errors.assigneeId?.message}
            data-cy="dropdown_assignee"
            {...controllerProps}
          />
        )}
      />
      <Button type="submit" data-cy="button_submit">
        {isEditing ? 'Edit' : 'Add new'}
      </Button>
    </StyledForm>
  );
};

export default EditCustomEventForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 16px;
  }

  & > .button {
    height: 40px;
  }

  .time-options {
    display: flex;

    .dropdown:first-of-type {
      margin-right: 16px;
    }
  }

  .optional {
    color: ${props => props.theme.colors.grayDark};
  }
`;
