import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { BUSINESS_HOURS_START, BUSINESS_HOURS_END } from './consts';
import { CalendarItem } from './calendar';

dayjs.extend(isBetween);

export function getWeeks(date: dayjs.Dayjs, weeks = 6) {
  const startDate = dayjs(date)
    .startOf('month')
    .startOf('week');

  let incrementDay: Dayjs = startDate.clone();
  const result = Array(weeks)
    .fill([])
    .map(() => {
      const days: dayjs.Dayjs[] = [];
      for (let i = 0; i < 7; i++) {
        days.push(incrementDay);
        incrementDay = incrementDay.add(1, 'day');
      }

      return days;
    });

  const lastWeekFirstDate = result[weeks - 1][0];
  if (lastWeekFirstDate.isAfter(date, 'month') && lastWeekFirstDate.day() === 0) {
    result.length = weeks - 1;
  }

  return result;
}

export function getDaysRange(startDate: dayjs.Dayjs, range = 30) {
  const date = dayjs(startDate).startOf('day');
  let index = 0;
  const result: Array<Dayjs> = [];

  while (index < range) {
    result.push(date.clone().add(index, 'day'));
    index++;
  }

  return result;
}

export function getHours() {
  const hours: number[] = [];

  for (let index = BUSINESS_HOURS_START; index < BUSINESS_HOURS_END + 1; index++) {
    hours.push(index);
  }

  return hours;
}

export const getWeekdays = (date: dayjs.Dayjs) => {
  const startDate = dayjs(date).startOf('week');
  const weekDays: dayjs.Dayjs[] = [];

  for (let index = 0; index < 7; index++) {
    weekDays.push(startDate.clone().add(index, 'days'));
  }

  return weekDays;
};

export const baseRoute = '/calendar';

// arcane spaghetti
export function getEventsWithOverlaps(events: CalendarItem[]) {
  // add overlap counter to each event and sort by start time
  const eventsWithOverlap = events
    .sort((event1, event2) => event1.start.unix() - event2.start.unix())
    .map(event => ({ overlaps: 0, event }));

  // separate events by date to reduce the amount of comparisons
  const separateByDay = {};
  eventsWithOverlap.forEach(item => {
    const key = `${item.event.start.month()}-${item.event.start.date()}`;
    if (separateByDay[key]) separateByDay[key].push(item);
    else separateByDay[key] = [item];
  });

  const calculateOverlaps = (items: { overlaps: number; event: CalendarItem }[]) => {
    return items.map((item1, idx) => {
      // first event can't have overlaps
      if (idx === 0) return item1;

      // current item
      const {
        event: { start: startCurr }
      } = item1;

      // compare to all events before current
      items.slice(0, idx).forEach(item2 => {
        const {
          overlaps: overlapsPrev,
          event: { start: startPrev, end: endPrev }
        } = item2;

        // overlap conditions. If event overlaps another which already has overlaps, sum their overlap counters
        if (startCurr.isSame(startPrev)) item1.overlaps = overlapsPrev + 1;
        if (startCurr.isBetween(startPrev, endPrev)) item1.overlaps = overlapsPrev + 1;
      });

      return item1;
    });
  };

  return Object.values(separateByDay)
    .map(day => calculateOverlaps(day as any))
    .flat();
}
