import React from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import PopMenu, { PopMenuProps } from 'components/PopMenu';

interface CalendarTooltipProps extends PopMenuProps {
  tooltipTitle?: React.ReactNode;
  onClose?: (event?: any) => void;
}

const modifiers = [
  { name: 'flip', options: { fallbackPlacements: ['top-start', 'right'] } },
  { name: 'preventOverflow', options: { padding: 16 } },
  { name: 'offset', options: { offset: [0, 15] } }
];

const CalendarTooltip: React.FC<CalendarTooltipProps> = ({
  className,
  children,
  tooltipTitle,
  placement = 'bottom-start',
  onClose,
  ...props
}) => {
  return (
    <StyledPopMenu
      placement={placement}
      modifiers={modifiers}
      onOutsideClick={onClose}
      className={clsx('calendar-tooltip', className)}
      unmountChildren
      strategy="fixed"
      {...props}
      data-cy="calendar_tooltip">
      <StyledCalendarTooltip>
        <Button onClick={onClose} className="button-close" data-cy="button_close_tooltip">
          <Icon name="mini-x" size={8} />
        </Button>
        <h3 className="title">{tooltipTitle}</h3>
        <div className="menu-content scrollable">{children}</div>
      </StyledCalendarTooltip>
    </StyledPopMenu>
  );
};

export default CalendarTooltip;

const StyledCalendarTooltip = styled.div`
  width: 324px;
  padding: 12px;
  background: #ffffff;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: ${props => props.theme.misc.borderRadius};

  .title {
    max-width: calc(100% - 24px);
    padding-right: 12px;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .button-close {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 24px;
    height: 24px;
    float: right;

    &:hover {
      fill: ${props => props.theme.colors.red};
    }
  }

  .menu-content {
    min-height: 60px;
    max-height: 448px;
    margin-right: -12px;
    padding-right: 12px;
    overflow: auto;
  }
`;

const StyledPopMenu = styled(PopMenu)`
  padding: 0;
`;
