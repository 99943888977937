import React, { useRef, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import Icon from 'components/Icon';
import PopMenu from 'components/PopMenu';

import { CalendarItem } from '../../calendar';
import CalendarContext from '../../CalendarContext';

interface CalendarEventProps extends React.HTMLAttributes<HTMLDivElement> {
  data: CalendarItem;
  onHide?: (id: number) => void;
}

const menuModifiers = [
  { name: 'flip', options: { fallbackPlacements: ['top'] } },
  { name: 'preventOverflow', options: { padding: 10 } },
  { name: 'offset', options: { offset: [0, 4] } }
];

const hideAnimationDuration = 500;

const CalendarEvent: React.FC<CalendarEventProps> = ({ className, data, onHide, ...props }) => {
  const {
    id,
    title,
    address,
    dateType,
    assignee,
    start,
    end,
    editable,
    location,
    visible,
    docId,
    projectType
  } = data;
  const [status, setStatus] = useState(data.status);
  const { setOpenEvent, toggleEventVisibility, toggleTaskCompleteStatus } = useContext(
    CalendarContext
  );
  const layout = useLayout();
  const menuTargetRef = useRef(null);
  const [openOptionsMenu, setOpenOptionsMenu] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const showCompleteButton = dateType === 'task';
  const showHideButton = ['task', 'deadline'].includes(dateType);
  const showEditButton = editable;
  const closing = title.toLowerCase().includes('closing');
  const isCompleted = status === 'Completed';

  const handleButtonClick = async e => {
    e.stopPropagation();

    if (openOptionsMenu) setOpenOptionsMenu(false);
    switch (e.target.value) {
      case 'edit':
        setOpenEvent(data);
        break;
      case 'hide':
        await toggleEventVisibility(data);
        setIsHiding(true);
        setTimeout(() => {
          if (onHide) onHide(id);
        }, hideAnimationDuration);
        break;
      case 'complete':
        await toggleTaskCompleteStatus(data);
        setStatus(status => (status === 'Completed' ? 'Not Completed' : 'Completed'));
        break;
      default:
        break;
    }
  };

  const handleOptionsButtonClick = e => {
    e.stopPropagation();
    setOpenOptionsMenu(value => !value);
  };

  return (
    <StyledCalendarEvent
      className={clsx(
        'calendar-event',
        dateType,
        { completed: isCompleted, hide: isHiding },
        className
      )}
      {...props}
      data-cy={`calendar_${dateType}_${id}`}>
      <div className="event-color" />
      <div className="event-info">
        {layout === 'mobile' ? (
          <Button
            ref={menuTargetRef}
            onClick={handleOptionsButtonClick}
            simple
            className={clsx('button-menu', { open: openOptionsMenu })}
            data-cy={`button_open_menu_${id}`}>
            <Icon name="menu-dots" />
          </Button>
        ) : (
          <div className="edit-buttons">
            {showCompleteButton && (
              <Button
                value="complete"
                onClick={handleButtonClick}
                simple
                data-cy={`button_complete_task_${id}`}>
                <Icon name="check" />
              </Button>
            )}
            {showHideButton && (
              <Button
                value="hide"
                onClick={handleButtonClick}
                className={clsx({ done: isCompleted })}
                data-cy={`button_hide_${id}`}
                simple>
                <Icon name={visible ? 'eye' : 'eye-crossed'} />
              </Button>
            )}
            {showEditButton && (
              <Button value="edit" onClick={handleButtonClick} simple data-cy={`button_edit_${id}`}>
                <Icon name="pen" />
              </Button>
            )}
          </div>
        )}

        <h3 className="name">
          {closing && (dateType === 'task' || dateType === 'event') && (
            <span className={clsx('closing', { confirmed: docId })} data-cy={`event_closing_${id}`}>
              <strong>{projectType}&nbsp;</strong>
              Closing
            </span>
          )}{' '}
          {closing && dateType === 'deadline' && (
            <>
              <span style={{ textTransform: 'capitalize' }}>{projectType}&nbsp;</span>
              Closing
            </>
          )}
          {!closing && title}
        </h3>
        <div className="address" data-cy={`event_address_${id}`}>
          {address}
        </div>
        {(dateType === 'task' || dateType === 'event') && (
          <>
            <hr className="divider" />
            {dateType === 'event' && (
              <div className="time">
                <Icon name="time" />
                <span className="time-date" data-cy={`event_time_${id}`}>
                  {start.format('ddd, MMMM D')}
                </span>
                {start.format('h:mm A')}
                {end?.format('- h:mm A')}
              </div>
            )}
            <div className="assignee">
              <Icon name="user" />
              {assignee || (
                <span className="not-assigned" data-cy={`event_assignee_${id}`}>
                  Not assigned
                </span>
              )}
            </div>
            {location && (
              <div className="location" data-cy={`event_location_${id}`}>
                <Icon name="location" />
                {location}
              </div>
            )}
          </>
        )}

        {layout === 'mobile' &&
          ReactDOM.createPortal(
            <StyledPopMenu
              modifiers={menuModifiers}
              open={openOptionsMenu}
              target={menuTargetRef.current}
              placement="bottom-start"
              strategy="fixed"
              onOutsideClick={() => setOpenOptionsMenu(false)}
              data-cy={`popmenu_${id}`}>
              <StyledOptionsMenu className="options-menu" onClick={e => e.stopPropagation()}>
                {showCompleteButton && (
                  <Button
                    value="complete"
                    onClick={handleButtonClick}
                    className={clsx({ done: isCompleted })}
                    data-cy={`button_complete_task_${id}`}
                    simple>
                    <Icon name="check" /> {isCompleted ? 'Mark as Not Done' : 'Mark as Done'}
                  </Button>
                )}
                {showHideButton && (
                  <Button
                    value="hide"
                    onClick={handleButtonClick}
                    className={clsx({ done: isCompleted })}
                    data-cy={`button_hide_${id}`}
                    simple>
                    <Icon name={visible ? 'eye' : 'eye-crossed'} /> {visible ? 'Hide' : 'Show'}
                  </Button>
                )}
                {showEditButton && (
                  <Button
                    value="edit"
                    onClick={handleButtonClick}
                    data-cy={`button_edit_event_${id}`}
                    simple>
                    <Icon name="pen" /> Edit
                  </Button>
                )}
              </StyledOptionsMenu>
            </StyledPopMenu>,
            document.getElementById('modal-root')!
          )}
      </div>
    </StyledCalendarEvent>
  );
};

export default React.memo(CalendarEvent);

const StyledCalendarEvent = styled.div`
  display: flex;
  border: 1px solid #dadada;
  padding: 12px 8px;
  font-size: 12px;
  line-height: 16px;
  border-radius: ${props => props.theme.misc.borderRadius};
  margin-bottom: 4px;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: #000000;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.task .event-color {
    background: ${props => props.theme.colors.lightGreen};
  }

  &.deadline .event-color {
    background: ${props => props.theme.colors.red};
  }

  &.event .event-color {
    background: ${props => props.theme.colors.blue};
  }

  &.completed .name {
    text-decoration: line-through;
  }

  .event-color {
    width: 4px;
    border-radius: 4px;
    background: black;
    flex-shrink: 0;
  }

  .button-edit,
  .button-menu,
  .edit-buttons > .button {
    width: 16px;
    height: 16px;
    fill: ${props => props.theme.colors.grayDark};
    z-index: 10;

    &.open .icon {
      fill: ${props => props.theme.colors.red};
    }

    &:hover {
      background: ${props => props.theme.colors.gray};
    }

    .icon {
      pointer-events: none;
    }
  }

  .button-menu {
    float: right;
    margin-left: auto;
  }

  .edit-buttons {
    display: flex;
    float: right;

    .button {
      position: initial;
      margin-left: 8px;
    }
  }

  .event-info {
    width: 100%;
    box-sizing: border-box;
    padding-left: 8px;
    overflow: hidden;

    .name {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin: 0 0 4px;
      box-sizing: border-box;
      max-width: 100%;

      .closing {
        display: inline-block;
        padding: 0 2px;
        font-size: 12px;
        line-height: 16px;
        color: ${props => props.theme.colors.blue};
        border: 1px solid ${props => props.theme.colors.blue};
        border-radius: ${props => props.theme.misc.borderRadius};
        text-transform: capitalize;

        &.confirmed {
          background: ${props => props.theme.colors.blue};
          color: #fff;
        }
      }
    }

    .address {
      color: ${props => props.theme.colors.grayDark};
    }

    hr {
      border-color: ${props => props.theme.colors.seashell};
      border-style: solid;
      margin: 8px 0;
    }

    .time,
    .assignee,
    .location {
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      margin-top: 8px;

      .icon {
        vertical-align: middle;
        margin-right: 10px;
        fill: ${props => props.theme.colors.grayDark};
      }
    }

    .time-date,
    .not-assigned {
      color: ${props => props.theme.colors.grayDark};
      margin-right: 5px;
    }

    .document {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 6px;
      background: none;
      border: 1px solid ${props => props.theme.colors.gray};
      border-radius: ${props => props.theme.misc.borderRadius};
      text-align: initial;
      margin-top: 8px;
      text-decoration: none;
      cursor: pointer;

      svg {
        margin-right: 6px;
      }

      &__name {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
      }

      &__isdocument {
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: ${props => props.theme.colors.grayDark};
      }
    }
  }

  &.hide {
    opacity: 0;
    transition: opacity ${hideAnimationDuration}ms;
  }
`;

const StyledOptionsMenu = styled.div`
  box-sizing: border-box;
  background: white;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: ${props => props.theme.misc.borderRadius};
  padding: 8px 0;

  .button {
    width: 100%;
    height: 32px;
    padding: 0 14px;
    font-weight: normal;
    justify-content: flex-start;

    &:hover {
      background: ${props => props.theme.colors.seashell};
    }

    .icon {
      fill: ${props => props.theme.colors.grayDark};
      margin-right: 8px;
      pointer-events: none;
    }

    &.done {
      .icon {
        fill: ${props => props.theme.colors.lightGreen};
      }
    }
  }
`;

const StyledPopMenu = styled(PopMenu)`
  padding: 0;
`;
