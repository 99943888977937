import React from 'react';
import clsx from 'clsx';

import { styled } from 'styles';

const FloatingItemsContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <StyledFloatingItemsContainer
      className={clsx('floating-items-container', className)}
      {...props}>
      <div className="inner-container">{children}</div>
    </StyledFloatingItemsContainer>
  );
};

export default FloatingItemsContainer;

const StyledFloatingItemsContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .inner-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
`;
