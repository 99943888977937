import React from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import Button from 'components/Button';
import Modal from 'components/Modal';

interface IComponentProps {
  modalTitle?: React.ReactNode;
  description?: React.ReactNode;
  open: boolean;
  className?: string;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmDeletionModal: React.FC<IComponentProps> = ({
  onSubmit,
  onClose,
  open,
  className,
  description,
  children,
  ...props
}) => {
  return (
    <StyledConfirmDeletionModal
      className={clsx('confirm', className)}
      onClose={onClose}
      open={open}
      {...props}>
      {children}
      <Button onClick={onClose} large className="cancel-btn" data-cy="button_cancel_delete">
        Cancel
      </Button>
      <Button onClick={onSubmit} large data-cy="button_confirm_delete">
        Delete
      </Button>
    </StyledConfirmDeletionModal>
  );
};

export default ConfirmDeletionModal;

const StyledConfirmDeletionModal = styled(Modal)`
  min-width: 300px;
  font-size: 16px;
  line-height: 24px;
  border-radius: ${props => props.theme.misc.borderRadius};

  .modal-title {
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-body {
    p {
      font-size: 14px;
    }
  }

  .close-btn {
    padding: 0;
    width: 24px;
    height: 24px;
    top: 12px;
    right: 12px;
    border-radius: ${props => props.theme.misc.borderRadius};

    svg {
      width: 8px;
      height: 8px;
    }
  }

  button.large {
    width: 100%;
    margin-top: 16px;

    &.cancel-btn {
      color: ${props => props.theme.colors.red};
      background: ${props => props.theme.colors.pink};
    }
  }
`;
