import React, { useMemo, useContext } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { styled } from 'styles';
import { getWeeks, getWeekdays } from '../calendarHelpers';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { CalendarItemType, CalendarItem } from '../calendar';
import CalendarContext from '../CalendarContext';

interface CellProps extends React.HTMLAttributes<HTMLDivElement> {
  today?: boolean;
  active?: boolean;
  past?: boolean;
  outside?: boolean;
  eventDots?: CalendarItemType[];
}

const Cell: React.FC<CellProps> = React.memo(
  ({ className, children, today, active, past, outside, eventDots = [], ...props }) => (
    <StyledCell
      className={clsx('cell', { today, active, past, outside }, className)}
      role="button"
      {...props}>
      <div className="cell__info">
        <span className="cell__title">{children}</span>
        {!outside && (
          <div className="cell__dots">
            {eventDots.map(item => (
              <svg key={item} className={`dot ${item}`} width="4" height="4" viewBox="0 0 4 4">
                <rect width="4" height="4" rx="2" />
              </svg>
            ))}
          </div>
        )}
      </div>
    </StyledCell>
  )
);

interface CalendarMiniatureProps {
  disabled?: boolean;
  events: CalendarItem[];
}

const CalendarMiniature: React.FC<CalendarMiniatureProps> = ({ disabled = false, events }) => {
  const { selectedDate, setSelectedDate, startDate, goToNextDate, goToPrevDate } = useContext(
    CalendarContext
  );
  const weeks = useMemo(() => getWeeks(startDate, 6), [startDate]);
  const weekdays = useMemo(() => getWeekdays(startDate), [startDate]);

  const handleDateClick = date => {
    if (!disabled) setSelectedDate(date);
  };

  const getEventIndicators = date => {
    const result: CalendarItemType[] = [];
    const dateEvents = events.filter(event => event.visible && event.start.isSame(date, 'day'));
    if (dateEvents.some(event => event.dateType === 'deadline')) result.push('deadline');
    if (dateEvents.some(event => event.dateType === 'task')) result.push('task');
    if (dateEvents.some(event => event.dateType === 'event')) result.push('event');
    return result;
  };

  return (
    <StyledCalendarMiniature className="calendar-miniature" data-cy="calendar_miniature">
      <div className="calendar-miniature__controls">
        <div className="current-date" data-cy="current_date">
          {startDate.format('MMM YYYY')}
        </div>
        <Button
          onClick={() => goToPrevDate('month')}
          className="button-back"
          simple
          data-cy="button_miniature_go_to_previous_date">
          <Icon name="arrow-simple" />
        </Button>
        <Button
          onClick={() => goToNextDate('month')}
          className="button-forward"
          simple
          data-cy="button_miniature_go_to_next_date">
          <Icon name="arrow-simple" style={{ transform: 'rotate(180deg)' }} />
        </Button>
      </div>
      <div className="weekdays">
        {weekdays.map(weekday => (
          <Cell key={weekday.unix()}>{weekday.format('ddd')}</Cell>
        ))}
      </div>

      <div className="weeks">
        {weeks.map((week, idx) => (
          <div key={idx} className="week">
            {week.map((day, idx) => (
              <Cell
                key={idx}
                eventDots={getEventIndicators(day)}
                today={day.isSame(dayjs(), 'day')}
                past={day.isBefore(dayjs(), 'day')}
                active={day.isSame(selectedDate, 'date')}
                outside={!day.isSame(startDate, 'month')}
                onClick={() => handleDateClick(day)}
                data-cy={`button_set_selected_date_${day.format('DD-MM-YYYY')}`}>
                {day.format('D')}
              </Cell>
            ))}
          </div>
        ))}
      </div>
    </StyledCalendarMiniature>
  );
};

export default CalendarMiniature;

const borderRadius = props => props.theme.misc.borderRadius;

const StyledCalendarMiniature = styled.div`
  padding: 12px 8px;
  z-index: 10;
  background: #ffffff;
  width: 100%;
  min-width: 300px;
  height: fit-content;
  border: 1px solid ${props => props.theme.colors.seashell};
  border-radius: ${props => props.theme.misc.borderRadius};

  .calendar-miniature__controls {
    display: flex;

    .current-date {
      display: flex;
      flex-basis: 100%;
      margin-right: auto;
      align-items: center;
      margin-right: auto;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      border: none;
      background: none;
      padding: 0;
      outline: none;
      cursor: pointer;
    }

    button {
      &.button-back,
      &.button-forward {
        width: 32px;
        height: 32px;
        margin-left: 8px;
        transition: ${props => props.theme.transitions.standart};
        &:hover,
        &:active {
          background: transparent;
        }
      }

      &.button-forward {
        margin-right: -4px;
      }
    }
  }

  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      background: ${props => props.theme.colors.seashell};
    }

    .cell {
      align-items: center;
      height: auto;
      padding: 8px 4px;

      .cell__info {
        color: ${props => props.theme.colors.grayDark};
        height: 22px;
        width: auto;
      }
    }
  }

  .weeks {
    margin-top: 8px;

    .week {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }
  }
`;

const StyledCell = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  height: 32px;
  box-sizing: border-box;
  padding: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  cursor: pointer;

  &.today,
  &.active {
    .cell__dots {
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }
  }

  &.active {
    z-index: 10;
    .cell__info {
      background: ${props => props.theme.colors.pink};
    }
    .cell__info:hover {
      border: 1px solid transparent;
    }
  }

  &.today {
    .cell__info {
      background: ${props => props.theme.colors.red};
      color: ${props => props.theme.colors.white};
    }
    .cell__title {
      color: ${props => props.theme.colors.white};
    }
  }

  &.past {
    color: ${props => props.theme.colors.grayDark};
  }

  &.outside {
    color: ${props => props.theme.colors.gray};
    pointer-events: none;
  }

  .cell__info {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: ${borderRadius};
    transition: ${props => props.theme.transitions.standart};
    border: 1px solid transparent;

    &:hover {
      border: 1px solid ${props => props.theme.colors.red};
    }
  }

  .cell__dots {
    position: absolute;
    display: inline-flex;
    opacity: 1;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.35s ease-out 0s;

    .dot {
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }

      &.deadline {
        fill: ${props => props.theme.colors.red};
      }
      &.task {
        fill: ${props => props.theme.colors.green};
      }
      &.event {
        fill: ${props => props.theme.colors.blue};
      }
    }
  }
`;
