import React from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import Icon from 'components/Icon';
// import { CalendarItem } from '../calendar';

interface CalendarEventCompactProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  data: CalendarItem;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, CalendarItem) => void;
}

const CalendarEventCompact: React.FC<CalendarEventCompactProps> = React.memo(
  ({ className, data, onClick, ...props }) => {
    const { id, start, end, dateType, status, title } = data;
    const isCustomEvent = dateType === 'event';

    const handleClick = e => {
      if (onClick) onClick(e, data);
    };

    return (
      <StyledCalendarEventCompact
        role="button"
        className={clsx(
          'calendar-event-compact',
          dateType,
          { completed: status === 'Completed' },
          className
        )}
        onClick={handleClick}
        {...props}
        data-cy={`calendar_${dateType}_compact_${id}`}>
        {!isCustomEvent && <div className="event-type" />}
        <span className="title">{title}</span>
        {isCustomEvent && (
          <span className="duration">
            <Icon name="time" />
            {start.format('h:mm A')} - {end?.format('h:mm A')}
          </span>
        )}
      </StyledCalendarEventCompact>
    );
  }
);

export default CalendarEventCompact;

const StyledCalendarEventCompact = styled.div`
  display: flex;
  margin-bottom: 8px;
  overflow: hidden;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .duration {
    width: 142px;
    flex-shrink: 0;
    font-size: 12px;
    line-height: 16px;
    margin-left: 10px;
    white-space: nowrap;

    svg {
      margin-right: 6px;
      fill: ${props => props.theme.colors.grayDark};
      vertical-align: top;
    }
  }

  .event-type {
    width: 4px;
    border-radius: ${props => props.theme.misc.borderRadius};
    margin-right: 8px;
  }

  &.deadline .event-type {
    background: ${props => props.theme.colors.red};
  }

  &.task .event-type {
    background: ${props => props.theme.colors.green};
  }

  &.event .title {
    font-weight: normal;
    color: #ffffff;
    background: ${props => props.theme.colors.blue};
    padding: 0 4px;
    border-radius: ${props => props.theme.misc.borderRadius};
  }

  &.completed .title {
    text-decoration: line-through;
  }
`;
