import React from 'react';
import dayjs from 'dayjs';

import { styled } from 'styles';
import { BUSINESS_HOURS_START, BUSINESS_HOURS_END } from '../consts';

interface TimeLineProps extends React.HTMLAttributes<HTMLDivElement> {
  time: dayjs.Dayjs;
  pixelsPerMinute: number;
  fullWidth?: boolean;
}

const businessHoursStartMoment = dayjs()
  .hour(BUSINESS_HOURS_START)
  .minute(0);

const TimeLine: React.FC<TimeLineProps> = React.memo(
  ({ time, pixelsPerMinute, fullWidth, ...props }) => {
    const hours = time.hour();
    if (hours < BUSINESS_HOURS_START || hours > BUSINESS_HOURS_END) return null;
    const dayOfWeek = time.day();
    const minutesFromStart = time.diff(businessHoursStartMoment, 'minute');
    const top = minutesFromStart * pixelsPerMinute;
    const left = fullWidth ? 0 : `calc(100% / 7 * ${dayOfWeek})`;
    const width = fullWidth ? '100%' : 'calc(100% / 7)';

    return (
      <StyledTimeline
        className="timeline"
        style={{ top, left, width }}
        {...props}
        data-cy="timeline">
        <svg width="5" height="5" viewBox="0 0 5 5" fill="none">
          <rect width="5" height="5" rx="2.5" fill="#DF1721" />
        </svg>
        <hr />
      </StyledTimeline>
    );
  }
);

export default TimeLine;

const StyledTimeline = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% / 7);
  z-index: 10;

  hr {
    border-width: 0 0 1px 0;
    border-color: ${props => props.theme.colors.red};
    margin: 0;
  }

  svg {
    position: absolute;
    top: -2px;
    left: -3.5px;
  }
`;
